"use client";
import { ArrowIcon } from "./icons";
import { useEffect, useRef, useState } from "react";
import { cn } from "@/utils/cn";
import { useTranslations } from "next-intl";

export interface CardProps {
  imageSrc?: string;
  imageOverlaySrc?: string;
  tags?: string[];
  title: string;
  subtitle?: string;
  ctaText?: string;
  className?: string;
  projectId?: string;
  isColSpanTwo?: boolean;
  backgroundColor?: boolean;
  hasFilter?: boolean;
}

export interface LazyBackgroundProps {
  src: string;
  children?: React.ReactNode;
  className?: string;
}

const LazyBackground = ({ src, children, className }: LazyBackgroundProps) => {
  const [loaded, setLoaded] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setLoaded(true);
            observer.disconnect();
          }
        });
      },
      { rootMargin: "0px", threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        const refObs = ref.current;
        observer.unobserve(refObs);
      }
    };
  }, []);

  return (
    <div
      ref={ref}
      className={className}
      style={{
        backgroundImage: loaded ? `url(${src})` : "none",
        transition: "background-image 0.5s ease-in-out",
      }}
    >
      {children}
    </div>
  );
};

export default LazyBackground;

export function Card({
  imageSrc,
  imageOverlaySrc,
  tags,
  title,
  subtitle,
  ctaText,
  className,
  projectId,
  isColSpanTwo,
  backgroundColor,
  hasFilter,
}: CardProps) {
  const cardRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const handleMouseEnter = () => {
    if (!videoRef.current) return;
    videoRef.current.play();
  };
  const handleMouseLeave = () => {
    if (!videoRef.current) return;

    videoRef.current.pause();
    videoRef.current.currentTime = 0;
  };

  const [isVisible, setIsVisible] = useState(false);
  // When inside the screen set isVisible to true
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );
    if (cardRef.current) {
      observer.observe(cardRef.current);
    }
    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  const t = useTranslations("Portfolio");

  return (
    <div
      ref={cardRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={cn(
        "flex group flex-col justify-between overflow-hidden relative grow bg-slate-500 rounded-xl",
        className,
        {
          // "bg-light-yellow": hasFilter,
        }
      )}
    >
      {tags && (
        <div
          className={cn(
            "absolute z-30 opacity-60 inset-x-0 top-0 bg-gradient-to-t to-slate-900 from-transparent h-1/2 uppercase",
            {
              "lg:bg-transparent lg:from-transparent lg:to-transparent": isColSpanTwo,
            }
          )}
        />
      )}
      <div
        className={cn(
          "absolute z-30 opacity-60 inset-x-0 bottom-0 bg-gradient-to-t from-slate-900 to-transparent h-1/2",
          {
            "lg:bg-transparent lg:from-transparent lg:to-transparent": isColSpanTwo,
          }
        )}
      />
      <div className="flex flex-col px-2 md:px-5 pb-5 pt-2 md:pt-5">
        <div
          className={cn("flex z-30 flex-wrap gap-2.5 content-start mt-0 text-lg text-white", {
            "lg:text-black": backgroundColor,
          })}
        >
          {tags &&
            tags.map((tag) => (
              <div
                key={tag}
                className={cn(
                  "justify-center px-2 pb-px border border-white border-solid rounded-full text-sm uppercase",
                  {
                    "lg:border-black": backgroundColor,
                  }
                )}
              >
                {t(tag)}
              </div>
            ))}
        </div>
      </div>
      {imageSrc && (
        <LazyBackground
          src={imageSrc}
          className={cn("card-image absolute inset-0 size-full bg-cover bg-center", {
            "lg:scale-65 rounded-xl lg:rounded-3xl shadow-imageCard": isColSpanTwo,
            "grayscale brightness-100 contrast-80": hasFilter,
          })}
        ></LazyBackground>
      )}
      {imageSrc && <div className="animate-pulse bg-light-gray absolute -z-10 size-full top-0 left-0 rounded-lg"></div>}
      {hasFilter && <div className={cn("bg-dark-gray mix-blend-lighten size-full absolute opacity-100")} />}
      {imageOverlaySrc && (
        <div className="group-hover:visible invisible absolute z-10 size-full">
          {isVisible && (
            <video
              ref={videoRef}
              autoPlay
              loop
              muted
              playsInline
              className={cn(
                "aspect-[2.69] size-full object-cover fill-[linear-gradient(270deg,rgba(28,33,46,0.30)_-8.28%,rgba(28,33,46,0.00)_93.13%),linear-gradient(270deg,rgba(28,33,46,0.30)_-8.28%,rgba(28,33,46,0.00)_93.13%)]",
                { "rounded-xl": isColSpanTwo }
              )}
            >
              <source src={imageOverlaySrc} type="video/mp4" />
            </video>
          )}
        </div>
      )}
      <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center z-30 text-sm sm:text-lg px-2 pb-2 md:px-5 md:pb-5">
        <div
          className={cn("text-white", {
            "lg:text-black": backgroundColor,
          })}
        >
          <div className="font-medium">{title}</div>

          {subtitle && (
            <div className="inline-block justify-center mt-2 px-1 md:px-2 border text-xs sm:text-sm border-white border-solid rounded-full">
              {subtitle}
            </div>
          )}
        </div>
        {projectId && ctaText && (
          <div
            className={cn("flex gap-2.5 lg:justify-center items-center text-yellow-200 rounded-full", {
              "lg:text-black": backgroundColor,
            })}
          >
            <div className="flex">{t(ctaText)}</div>
            {backgroundColor ? (
              <>
                <ArrowIcon className="w-7 lg:hidden" color="light-yellow" />
                <ArrowIcon className="w-7 hidden lg:block" color="black" />
              </>
            ) : (
              <ArrowIcon className="w-7" color="light-yellow" />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
