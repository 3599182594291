type PortfolioItem = {
  imageSrc: string;
  imageOverlaySrc: string;
  tags: string[];
  metaTitle: string;
  title: string;
  titleTwo: string;
  description: string;
  ctaText: string;
  images: string[];
  videoProject?: string;
  projectTitle: string;
  client: string;
  workAreas: string[];
  tecnologies: string[];
  projectDescription: string;
  titleDiscoverProject: string;
  descriptionExperienceDev: string;
  websiteProject?: string;
  webappProject?: string;
  appGoogleProject?: string;
  appAppleProject?: string;
};

export const getPortfolioItems = (locale: string): Record<string, PortfolioItem> => ({
  crgs: {
    imageSrc: `/${locale}/portfolio/crgs/8.png.webp`,
    imageOverlaySrc: `/${locale}/portfolio/crgs/crgs.mp4`,
    tags: ["uiUx", "brandId", "development"],
    metaTitle: "crg",
    title: "CRGS",
    titleTwo: "titleTwoCrgs",
    description: "descriptionCrgs",
    ctaText: "goToProject",
    images: [
      `/${locale}/portfolio/crgs/1.jpg.webp`,
      `/${locale}/portfolio/crgs/2.png.webp`,
      "",
      `/${locale}/portfolio/crgs/4.png.webp`,
      `/${locale}/portfolio/crgs/5.png.webp`,
      `/${locale}/portfolio/crgs/6.png.webp`,
      `/${locale}/portfolio/crgs/7.png.webp`,
      `/${locale}/portfolio/crgs/8.png.webp`,
      `/${locale}/portfolio/crgs/9.png.webp`,
      `/${locale}/portfolio/crgs/10.png.webp`,
      `/${locale}/portfolio/crgs/11.png.webp`,
      `/${locale}/portfolio/crgs/12.jpg.webp`,
    ],
    videoProject: `/${locale}/portfolio/crgs/crgs.mp4`,
    projectTitle: "crgs",
    client: "projectDescription",
    workAreas: ["brandIdentity", "uxUiDesign", "noCodeDevelopment"],
    tecnologies: ["webflow"],
    projectDescription: "textTopProjectCrgs",
    titleDiscoverProject: "discoverCrgs",
    descriptionExperienceDev: "textBottomProjectCrgs",
    websiteProject: "https://www.centrogenomica.it/",
  },
  yeki: {
    imageSrc: `/${locale}/portfolio/yeki/yeki.jpg.webp`,
    imageOverlaySrc: `/${locale}/portfolio/yeki/VideoCard.mp4`,
    tags: ["uiUx", "brandId", "development"],
    metaTitle: "yek",
    title: "YEKI",
    titleTwo: "titleTwoYeki",
    description: "descriptionYeki",
    ctaText: "goToProject",
    images: [
      `/${locale}/portfolio/yeki/1.png.webp`,
      `/${locale}/portfolio/yeki/2.png.webp`,
      `/${locale}/portfolio/yeki/3.jpg.webp`,
      `/${locale}/portfolio/yeki/4.png.webp`,
      `/${locale}/portfolio/yeki/5.jpg.webp`,
      `/${locale}/portfolio/yeki/6.jpg.webp`,
      `/${locale}/portfolio/yeki/12.png.webp`,
      `/${locale}/portfolio/yeki/7.jpg.webp`,
      `/${locale}/portfolio/yeki/8.jpg.webp`,
      `/${locale}/portfolio/yeki/9.jpg.webp`,
      `/${locale}/portfolio/yeki/10.jpg.webp`,
      `/${locale}/portfolio/yeki/11.png.webp`,
    ],
    projectTitle: "yeki",
    client: "yekiRent",
    workAreas: ["brandIdentity", "uxUiDesign", "development"],
    tecnologies: ["webflow", "react"],
    projectDescription: "textTopProjectYeki",
    titleDiscoverProject: "discoverYeki",
    descriptionExperienceDev: "textBottomProjectYeki",
    websiteProject: "https://www.yeki.rent/",
    webappProject: "https://app.yeki.rent/",
  },
  lapillus: {
    imageSrc: `/${locale}/portfolio/lapillus/lapillus.png.webp`,
    imageOverlaySrc: `/${locale}/portfolio/lapillus/lapillus.mov`,
    tags: ["uiUx", "brandId", "development"],
    metaTitle: "lapill",
    title: "LAPILLUS",
    titleTwo: "titleTwoLapillus",
    description: "descriptionLapillus",
    ctaText: "goToProject",
    images: [
      `/${locale}/portfolio/lapillus/1.png.webp`,
      `/${locale}/portfolio/lapillus/2.jpg.webp`,
      `/${locale}/portfolio/lapillus/3.jpg.webp`,
      `/${locale}/portfolio/lapillus/4.png.webp`,
      `/${locale}/portfolio/lapillus/5.png.webp`,
      `/${locale}/portfolio/lapillus/6.png.webp`,
      `/${locale}/portfolio/lapillus/7.png.webp`,
      `/${locale}/portfolio/lapillus/8.png.webp`,
      `/${locale}/portfolio/lapillus/9.png.webp`,
      `/${locale}/portfolio/lapillus/10.png.webp`,
      `/${locale}/portfolio/lapillus/11.jpg.webp`,
      `/${locale}/portfolio/lapillus/12.png.webp`,
    ],
    videoProject: `/${locale}/portfolio/lapillus/lapillus.mov`,
    projectTitle: "lapillus",
    client: "lapillus",
    workAreas: ["illustration", "uxUiDesign", "nftBlockchain", "development"],
    tecnologies: ["react", "next", "cmsCustom"],
    projectDescription: "textTopProjectLapillus",
    titleDiscoverProject: "discoverLapillus",
    descriptionExperienceDev: "textBottomProjectLapillus",
    websiteProject: "https://lapillus.armonia.io/",
  },
  moonstone: {
    imageSrc: `/${locale}/portfolio/moonstone/moonstone.png.webp`,
    imageOverlaySrc: `/${locale}/portfolio/moonstone/moonstone-vid.mp4`,
    tags: ["uiUx", "brandId", "development"],
    metaTitle: "moonst",
    title: "MOONSTONE",
    titleTwo: "titleTwoMoonstone",
    description: "descriptionMoonstone",
    ctaText: "goToProject",
    images: [
      `/${locale}/portfolio/moonstone/1.png.webp`,
      `/${locale}/portfolio/moonstone/2.png.webp`,
      "",
      "",
      "",
      "",
      "",
      "",
      `/${locale}/portfolio/moonstone/9.png.webp`,
      `/${locale}/portfolio/moonstone/10.png.webp`,
      `/${locale}/portfolio/moonstone/11.png.webp`,
      `/${locale}/portfolio/moonstone/12.png.webp`,
    ],
    videoProject: `/${locale}/portfolio/moonstone/moonstone-vid.mp4`,
    projectTitle: "moonstone",
    client: "moonstone",
    workAreas: ["illustration", "uxUiDesign", "noCodeDevelopment"],
    tecnologies: ["webflow"],
    projectDescription: "textTopProjectMoonstone",
    titleDiscoverProject: "discoverMoonstone",
    descriptionExperienceDev: "textBottomProjectMoonstone",
    websiteProject: "https://moonstone-fund.webflow.io/",
  },
  sarim: {
    imageSrc: `/${locale}/portfolio/sarim/sarim.png.webp`,
    imageOverlaySrc: `/${locale}/portfolio/sarim/sarimvideoesterno.mp4`,
    tags: ["uiUx", "brandId", "development"],
    metaTitle: "sar",
    title: "SARIM",
    titleTwo: "titleTwoSarim",
    description: "descriptionSarim",
    ctaText: "goToProject",
    images: [
      `/${locale}/portfolio/sarim/1.jpg.webp`,
      `/${locale}/portfolio/sarim/2.png.webp`,
      "",
      `/${locale}/portfolio/sarim/4.png.webp`,
      `/${locale}/portfolio/sarim/5.png.webp`,
      `/${locale}/portfolio/sarim/6.png.webp`,
      `/${locale}/portfolio/sarim/7.png.webp`,
      `/${locale}/portfolio/sarim/8.png.webp`,
      `/${locale}/portfolio/sarim/9.png.webp`,
      `/${locale}/portfolio/sarim/10.png.webp`,
      `/${locale}/portfolio/sarim/11.png.webp`,
      `/${locale}/portfolio/sarim/12.jpg.webp`,
    ],
    videoProject: `/${locale}/portfolio/sarim/sarimvideointerno.mp4`,
    projectTitle: "sarim",
    client: "sarimAmbiente",
    workAreas: ["illustration", "uxUiDesign", "development"],
    tecnologies: ["react", "capacitor", "nodeJs", "mongoDB"],
    projectDescription: "textTopProjectSarim",
    titleDiscoverProject: "discoverSarim",
    descriptionExperienceDev: "textBottomProjectSarim",
    appGoogleProject: "https://play.google.com/store/search?q=sarim+ambiente&c=apps&hl=it",
    appAppleProject: "https://apps.apple.com/it/app/sarim-ambiente/id1578928561",
  },
  // salernoBnb: {
  //   imageSrc: "/portfolio/salerno-bnb/deartour.jpg.webp",
  //   imageOverlaySrc: "",
  //   tags: ["UI UX", "BRAND ID"],
  //   title: "SALERNO B&B",
  //   ctaText: "VAI AL PROGETTO",
  //   images: ["", "", "", "", "", "", "", "", "", "", "", ""],
  //   videoProject: "",
  //   projectTitle: "",
  //   client: "",
  //   workAreas: [],
  //   tecnologies: [],
  //   projectDescription:
  //     "Con Sarim, abbiamo rinnovato un’applicazione da noi precedentemente sviluppata, rendendo l’interfaccia dell’app fresco e giovanile in base alle attuali tendenze di UI design. Abbiamo introdotto un ecovocabolario che, attraverso l’uso di illustrazioni, vuole educare gli utenti sul corretto conferimento dei rifiuti. All’interno dell’app abbiamo integrato un software gestionale sviluppato internamente, per raccogliere e gestire dati, prenotazioni di ritiro dei rifiuti e segnalazioni degli utenti.",
  //   titleDiscoverProject: "Scopri Salerno B&B",
  //   descriptionExperienceDev:
  //     "Grazie alle nuove integrazioni di geolocalizzazione e gestione di rifiuti ingombranti, Sarim si afferma come una soluzione digitale all’avanguardia per una cittadinanza eco-responsabile e informata, che contribuisce a rafforzare il nostro impegno nello sviluppo di app moderne e socialmente utili.",
  // },
  joy: {
    imageSrc: `/${locale}/portfolio/joy/joy.jpg.webp`,
    imageOverlaySrc: `/${locale}/portfolio/joy/videocard.mp4`,
    tags: ["uiUx", "brandId", "development"],
    metaTitle: "jo",
    title: "JOY",
    titleTwo: "titleTwoJoy",
    description: "descriptionJoy",
    ctaText: "goToProject",
    images: [
      `/${locale}/portfolio/joy/1.jpg.webp`,
      `/${locale}/portfolio/joy/2.jpg.webp`,
      "",
      `/${locale}/portfolio/joy/4.jpg.webp`,
      `/${locale}/portfolio/joy/5.png.webp`,
      `/${locale}/portfolio/joy/6.png.webp`,
      `/${locale}/portfolio/joy/7.png.webp`,
      `/${locale}/portfolio/joy/8.png.webp`,
      `/${locale}/portfolio/joy/9.png.webp`,
      `/${locale}/portfolio/joy/10.png.webp`,
      `/${locale}/portfolio/joy/11.png.webp`,
      `/${locale}/portfolio/joy/12.png.webp`,
    ],
    videoProject: `/${locale}/portfolio/joy/videointerno.mp4`,
    projectTitle: "joy",
    client: "joyForPeople",
    workAreas: ["brandIdentity", "uxUiDesign", "noCode", "development"],
    tecnologies: ["react", "webflow"],
    projectDescription: "textTopProjectJoy",
    titleDiscoverProject: "discoverJoy",
    descriptionExperienceDev: "textBottomProjectJoy",
    websiteProject: "https://www.joy-app.it/",
    appGoogleProject: "https://play.google.com/store/apps/details?id=com.joysrl.app",
    appAppleProject: "https://apps.apple.com/it/app/joy-benessere-a-domicilio/id6447368285",
  },
  cajo: {
    imageSrc: `/${locale}/portfolio/cajo/cajo.jpg.webp`,
    imageOverlaySrc: `/${locale}/portfolio/cajo/videocard.mp4`,
    tags: ["uiUx", "development"],
    metaTitle: "caj",
    title: "CAJO",
    titleTwo: "titleTwoCajo",
    description: "descriptionCajo",
    ctaText: "goToProject",
    images: [
      `/${locale}/portfolio/cajo/1.jpg.webp`,
      `/${locale}/portfolio/cajo/2.png.webp`,
      "",
      `/${locale}/portfolio/cajo/4.png.webp`,
      `/${locale}/portfolio/cajo/5.png.webp`,
      `/${locale}/portfolio/cajo/6.png.webp`,
      `/${locale}/portfolio/cajo/7.png.webp`,
      `/${locale}/portfolio/cajo/8.png.webp`,
      `/${locale}/portfolio/cajo/9.png.webp`,
      `/${locale}/portfolio/cajo/10.png.webp`,
      `/${locale}/portfolio/cajo/11.png.webp`,
      `/${locale}/portfolio/cajo/12.png.webp`,
    ],
    videoProject: `/${locale}/portfolio/cajo/Cajo.mp4`,
    projectTitle: "cajo",
    client: "cajo",
    workAreas: ["uxUiDesign", "developmentChip"],
    tecnologies: ["react", "next", "cmsCustom"],
    projectDescription: "textTopProjectCajo",
    titleDiscoverProject: "discoverCajo",
    descriptionExperienceDev: "textBottomProjectCajo",
    webappProject: "",
  },
  carpino: {
    imageSrc: `/${locale}/portfolio/carpino/carpino-card.png.webp`,
    imageOverlaySrc: `/${locale}/portfolio/carpino/Carpino.mp4`,
    tags: ["uiUx", "development"],
    metaTitle: "carp",
    title: "CARPINO",
    titleTwo: "titleTwoCarpino",
    description: "descriptionCarpino",
    ctaText: "goToProject",
    images: [
      `/${locale}/portfolio/carpino/1.jpg.webp`,
      `/${locale}/portfolio/carpino/2.png.webp`,
      "",
      `/${locale}/portfolio/carpino/4.png.webp`,
      `/${locale}/portfolio/carpino/5.png.webp`,
      `/${locale}/portfolio/carpino/6.png.webp`,
      `/${locale}/portfolio/carpino/7.png.webp`,
      `/${locale}/portfolio/carpino/8.png.webp`,
      `/${locale}/portfolio/carpino/9.png.webp`,
      `/${locale}/portfolio/carpino/10.png.webp`,
      `/${locale}/portfolio/carpino/11.png.webp`,
      `/${locale}/portfolio/carpino/12.png.webp`,
    ],
    videoProject: `/${locale}/portfolio/carpino/Carpino.mp4`,
    projectTitle: "carpino",
    client: "carpinoSrl",
    workAreas: ["uxUiDesign", "noCodeDevelopment"],
    tecnologies: ["webflow"],
    projectDescription: "textTopProjectCarpino",
    titleDiscoverProject: "discoverCarpino",
    descriptionExperienceDev: "textBottomProjectCarpino",
    websiteProject: "https://www.carpinosrl.com/",
  },
});
